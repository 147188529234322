import React, { useState, useEffect, useRef, useMemo } from "react";
// import { Jodit } from "jodit";
import JoditReact from "jodit-react";
import axios from "axios";
import { HEADER_TOKEN } from "../config";

const JoditEditor = (props) => {
  // const [editorContent, setEditorContent] = useState("");

  const { handleEditor } = props;
  const editor = useRef(null);

  const editorConfig = {
    filebrowser: {
      ajax: {
        url: "https://xdsoft.net/jodit/finder/",
      },
    },
    width: "100%",
    maxWidth: "100%",
    height: "auto",
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "large",
    // toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    placeholder: "Write something awesome ...",
    // beautyHTML: true,
    controls: {
      font: {
        command: "fontname",
        data: {
          cssRule: "font-family",
          //normalize: "function(e){return e.toLowerCase().replace(/['\"]+/g,\"\").replace(/[^a-z0-9]+/g,\",\")}"
        },
        list: {
          "": "Default",
          "Quicksand,sans-serif": "Quicksand",
          "Helvetica,sans-serif": "Helvetica",
          "Arial,Helvetica,sans-serif": "Arial",
          "Georgia,serif": "Georgia",
          "Impact,Charcoal,sans-serif": "Impact",
          "Tahoma,Geneva,sans-serif": "Tahoma",
          "'Times New Roman',Times,serif": "Times New Roman",
          "Verdana,Geneva,sans-serif": "Verdana",
          "Lucida Console": "Lucida Console",
          "Lucida Handwriting": "Lucida Handwriting",
          Papyrus: "Papyrus",
          Monaco: "Monaco",
          Roboto: "Roboto",
          "Brush Script MT": "Brush Script MT",
          "Courier New": "Courier New",
          monospace: "monospace",
        },
        exec: 'function(e,t,n){var r=n.control;return a.memorizeExec(e,t,{control:r},(function(t){var n;return"fontsize"===(null===(n=r.command)||void 0===n?void 0:n.toLowerCase())?""+t+e.o.defaultFontSizePoints:t}))}',
        childTemplate: function (e, t, n) {
          return `<span style="font-family:${t}!important;">${n}</span>`;
        },
        tooltip: "Font family",
        isChildActive:
          'function(e,t){var n,r,o=e.s.current(),s=(null===(n=t.data)||void 0===n?void 0:n.cssRule)||"font-size",l=(null===(r=t.data)||void 0===r?void 0:r.normalize)||function(t){return/pt$/i.test(t)&&"pt"===e.o.defaultFontSizePoints?t.replace(/pt$/i,""):t};if(o){var u=i.Dom.closest(o,(function(t){return i.Dom.isBlock(t,e.ew)||t&&i.Dom.isElement(t)}),e.editor)||e.editor,c=a.css(u,s);return Boolean(c&&t.args&&l(t.args[0].toString())===l(c.toString()))}return!1}',
      },
      image: {
        // name: "Insert Image",
        // iconURL: "URL_OF_YOUR_IMAGE_ICON",
        exec: async (editor) => {
          await imageUpload(editor);
        },
      },
    },
    // extraButtons: [],
  };

  // useEffect(() => {
  //   uploadImageButton();
  //   // codeBlockButton();
  // }, []);

  // const uploadImageButton = () => {
  //   Jodit.defaultOptions.controls.uploadImage = {
  //     name: "Upload image to server",
  //     iconURL:
  //       "https://www.kindpng.com/picc/m/261-2619141_cage-clipart-victorian-cloud-upload-icon-svg-hd.png",
  //     exec: async (editor) => {
  //       await imageUpload(editor);
  //     },
  //   };
  // };

  // const codeBlockButton = () => {
  //   Jodit.defaultOptions.controls.codeBlock = {
  //     name: "Code Block",
  //     iconURL:
  //       "https://cdn.icon-icons.com/icons2/2406/PNG/512/codeblock_editor_highlight_icon_145997.png",
  //     exec: async (editor) => {
  //       const pre = editor.selection.j.createInside.element("pre");
  //       pre.style = "background-color:#F0F0F0; text-align:left; padding:10px";
  //       pre.innerHTML = `${editor.selection.html}`;
  //       editor.selection.insertNode(pre);
  //     },
  //   };
  // };

  const imageUpload = (editor) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async function () {
      const imageFile = input.files[0];

      if (!imageFile) {
        return;
      }

      if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
        return;
      }

      let data = new FormData();
      data.append("myfile", imageFile);
      data.append("product_name", "testing");

      // const imageInfo = await FileUpload(imageFile);
      const imageInfo = await axios.post(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ELEARNING_API_VERSION}/upload`,
        data,
        HEADER_TOKEN
      );

      insertImage(editor, imageInfo.data.result);
    }.bind(this);
  };

  const insertImage = (editor, url) => {
    const image = editor.selection.j.createInside.element("img");
    image.setAttribute("src", url);
    editor.selection.insertNode(image);
  };

  return useMemo(
    () => (
      <div style={{ maxWidth: editorConfig.width, margin: "0 auto" }}>
        <JoditReact
          value={props.value}
          config={editorConfig}
          ref={editor}
          onChange={(newContent) => handleEditor(newContent)}
          onBlur={(value, event) => console.log(event)}
        />
      </div>
    ),
    []
  );
};

export default JoditEditor;
